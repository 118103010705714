import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

export function StrecEarthquake({ eventId, token, apiUrl }) {
  const [strecData, setStrecData] = useState(null);
  const [strecError, setStrecError] = useState(null);

  const fetchStrecData = useCallback(async () => {
    if (!token || !eventId) return;

    try {
      const result = await fetch(`${apiUrl}/v1/shake-data/strec/${eventId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!result.ok) {
        if (result.status === 404) {
          setStrecError("No STREC data available");
        } else {
          setStrecError(`Error: ${result.status} - ${result.statusText}`);
        }
        setStrecData(null);
        return;
      }

      const data = await result.json();
      if (
        data.responseCode === 200 &&
        typeof data.data === "object" &&
        data.data !== null
      ) {
        setStrecData(data.data);
      } else {
        setStrecData(null);
      }
    } catch (err) {
      setStrecError(err.message);
      setStrecData(null);
    }
  }, [token, eventId, apiUrl]);

  useEffect(() => {
    fetchStrecData();
  }, [fetchStrecData]);

  return (
    <Box>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        SeismoTectonic Regime Earthquake Calculator (STREC)
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {strecError ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography color="error">{strecError}</Typography>
                </TableCell>
              </TableRow>
            ) : strecData && typeof strecData === "object" ? (
              Object.entries(strecData).map(([key, value], index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {key.replace(/([A-Z])/g, " $1").trim()}
                  </TableCell>
                  <TableCell>
                    {typeof value === "number"
                      ? value.toFixed(2)
                      : value === null
                      ? "N/A"
                      : value.toString()}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No STREC data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
