import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config/apiConfig";
import { getUserRole } from "../../utils/getUserRole";
import { showToast } from "../../utils/toastUtils"; // Import toast

export default function LatestEarthquakeInformation() {
  const [earthquake, setEarthquake] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const userRole = getUserRole();

  useEffect(() => {
    const fetchLatestEarthquake = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/earthquakes/latest`);

        if (response.status === 404) {
          setEarthquake(null);
          throw new Error("Tidak ada data gempa terbaru");
        }

        if (!response.ok) {
          throw new Error(
            `Failed to fetch latest earthquake: ${response.statusText}`
          );
        }

        const earthquakeData = await response.json();
        setEarthquake(earthquakeData.data);
        setIsLoaded(true);
      } catch (err) {
        setError(err.message);
        showToast("error", err.message, { autoClose: 3000 });
      } finally {
        setLoading(false);
      }
    };

    fetchLatestEarthquake();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      showToast("success", "Data gempa terakhir berhasil dimuat!", {
        autoClose: 2000,
      });
    }
  }, [isLoaded]);

  return (
    <Box sx={{ flex: 1 }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Latest Earthquake Information
      </Typography>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : earthquake ? (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>Event ID</strong>
                </TableCell>
                <TableCell>{earthquake.eventId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Date</strong>
                </TableCell>
                <TableCell>
                  {new Date(earthquake.date).toLocaleDateString("id-ID")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Time</strong>
                </TableCell>
                <TableCell>{earthquake.time}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Latitude</strong>
                </TableCell>
                <TableCell>{earthquake.latitude}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Longitude</strong>
                </TableCell>
                <TableCell>{earthquake.longitude}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Magnitude</strong>
                </TableCell>
                <TableCell>{earthquake.magnitude}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Depth</strong>
                </TableCell>
                <TableCell>{earthquake.depth} km</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Location</strong>
                </TableCell>
                <TableCell>{earthquake.location}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Detail Page</strong>
                </TableCell>
                <TableCell>
                  <button
                    variant="outlined"
                    size="small"
                    onClick={() =>
                      navigate(`/${userRole}/earthquake/${earthquake.id}`)
                    }
                  >
                    View Details
                  </button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={{ color: "gray", mt: 2 }}>
          Tidak ada gempa baru yang ditambahkan.
        </Typography>
      )}
    </Box>
  );
}
