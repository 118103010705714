export const getColorForMmiLevel = (mmiLevel) => {
  // Pastikan mmiLevel adalah angka
  const level = parseInt(mmiLevel, 10); // Mengonversi mmiLevel ke angka
  console.log(`Level MMI: ${level}`); // Tambahkan log untuk debugging

  // Tentukan warna berdasarkan level MMI
  if (level >= 10) return "rgb(200, 0, 0)"; // Dark Red for level 10 and above
  if (level >= 9) return "rgb(255, 0, 0)"; // Red for level 9
  if (level >= 8) return "rgb(255, 145, 0)"; // Orange for level 8
  if (level >= 7) return "rgb(255, 200, 0)"; // Yellow-Orange for level 7
  if (level >= 6) return "rgb(255, 255, 0)"; // Yellow for level 6
  if (level >= 5) return "rgb(122, 255, 147)"; // Light Green for level 5
  if (level >= 4) return "rgb(128, 255, 255)"; // Light Cyan for level 4
  if (level >= 3) return "rgb(160, 230, 255)"; // Light Blue for level 3
  if (level >= 2) return "rgb(191, 204, 255)"; // Light Blue for level 2
  return "rgb(255, 255, 255)"; // White for level 0-1
};
