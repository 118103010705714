import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { BarChart } from "@mui/x-charts/BarChart";
import { useTheme } from "@mui/material/styles";
import { API_URL } from "../../config/apiConfig";
import { showToast } from "../../utils/toastUtils"; // Import toast

export default function EarthquakeBarChart() {
  const theme = useTheme();
  const [earthquakeData, setEarthquakeData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/v1/earthquakes`)
      .then((res) => res.json())
      .then((data) => {
        if (data.responseCode === 200) {
          const currentYear = new Date().getFullYear();
          const recentYears = 5; // Menampilkan hanya 5 tahun terakhir

          // Filter hanya gempa dari beberapa tahun terakhir
          const filteredData = data.data.filter((quake) => {
            const quakeYear = new Date(quake.date).getFullYear();
            return quakeYear >= currentYear - recentYears;
          });

          // Hitung total gempa per tanggal
          const counts = filteredData.reduce((acc, quake) => {
            const date = quake.date.split("T")[0]; // Ambil bagian tanggal saja
            acc[date] = (acc[date] || 0) + 1;
            return acc;
          }, {});

          // Ubah objek menjadi array dan urutkan dari terbaru ke terlama
          const formattedData = Object.entries(counts)
            .map(([date, count]) => ({ date, count }))
            .sort((a, b) => new Date(b.date) - new Date(a.date));

          setEarthquakeData(formattedData);
          setIsLoaded(true);
        } else {
          throw new Error("Gagal mengambil data gempa.");
        }
      })
      .catch((err) => {
        console.error("Error fetching earthquakes:", err);
        showToast("error", "Gagal memuat data gempa di grafik!", {
          autoClose: 3000,
        });
      });
  }, []);

  useEffect(() => {
    if (isLoaded) {
      showToast("success", "Data gempa berhasil dimuat di grafik!", {
        autoClose: 2000,
      });
    }
  }, [isLoaded]);

  const colorPalette = [
    (theme.vars || theme).palette.primary.dark,
    (theme.vars || theme).palette.primary.main,
    (theme.vars || theme).palette.primary.light,
  ];

  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Earthquake Events Per Date (Last 5 Years)
        </Typography>{" "}
        <BarChart
          borderRadius={8}
          colors={colorPalette}
          xAxis={[
            {
              scaleType: "band",
              categoryGapRatio: 0.5,
              data: earthquakeData.map((item) => item.date), // Tanggal di sumbu X
            },
          ]}
          series={[
            {
              id: "earthquakes",
              label: "Total Events",
              data: earthquakeData.map((item) => item.count), // Total kejadian di sumbu Y
              stack: "A",
            },
          ]}
          height={300}
          margin={{ left: 50, right: 0, top: 20, bottom: 50 }}
          grid={{ horizontal: true }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </CardContent>
    </Card>
  );
}
