import { useEffect } from "react";
import { showToast } from "./toastUtils"; // Import helper toast
import { SHAKEMAP_API_URL } from "../config/apiConfig";

export const useSSE = () => {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.warn("⚠️ No JWT token found. SSE connection will fail.");
      return;
    }

    const sseEndpoints = [
      { url: `${SHAKEMAP_API_URL}/v1/sse`, sourceName: "ShakeMap API" },
    ];

    const controllers = sseEndpoints.map(({ url, sourceName }) => {
      const controller = new AbortController();
      const { signal } = controller;

      (async () => {
        try {
          console.log(`🔗 Connecting to SSE: ${url}`);

          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            signal,
          });

          if (!response.ok) {
            throw new Error(`❌ Failed to connect: ${response.statusText}`);
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();

          while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            const decoded = decoder.decode(value, { stream: true });

            decoded
              .split("\n")
              .filter((line) => line.startsWith("data: "))
              .forEach((line) => {
                try {
                  const data = JSON.parse(line.replace("data: ", ""));
                  console.log(`📡 [${sourceName}] SSE Received:`, data);

                  // 🔔 Gunakan helper showToast()
                  showToast("info", `🔔 [${sourceName}] ${data.message}`);
                } catch (error) {
                  console.error(
                    `❌ Error parsing SSE from ${sourceName}:`,
                    error
                  );
                }
              });
          }
        } catch (error) {
          console.error(`❌ SSE Error from ${sourceName}:`, error);
        }
      })();

      return controller;
    });

    return () => {
      controllers.forEach((controller) => controller.abort());
      console.log("❌ All SSE connections closed.");
    };
  }, []);
};
