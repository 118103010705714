import React, { useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const ModalAddEvacuationPoint = ({
  openDialog,
  setOpenDialog,
  formData,
  setFormData,
  provinces,
  cities,
  districts,
  villages,
  fetchCities,
  fetchDistricts,
  fetchVillages,
  handleAdd,
}) => {
  const provinceInputRef = useRef(null);

  useEffect(() => {
    if (openDialog && provinceInputRef.current) {
      setTimeout(() => {
        provinceInputRef.current.focus();
      }, 100); // Delay sedikit untuk memastikan modal sudah terbuka
    }
  }, [openDialog]);

  return (
    <Dialog
      open={openDialog}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") return;
      }}
      disableEscapeKeyDown
    >
      <DialogTitle>Add Evacuation Point</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={provinces}
          getOptionLabel={(option) => option.name}
          value={provinces.find((p) => p.code === formData.province) || null}
          onChange={(event, newValue) => {
            const code = newValue?.code || "";
            setFormData({
              ...formData,
              province: code,
              city: "",
              district: "",
              village: "",
            });
            fetchCities(code);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Province"
              fullWidth
              margin="normal"
              inputRef={provinceInputRef} // Autofocus ke sini
            />
          )}
        />
        <Autocomplete
          options={cities}
          getOptionLabel={(option) => option.name}
          value={cities.find((c) => c.code === formData.city) || null}
          onChange={(event, newValue) => {
            const code = newValue?.code || "";
            setFormData({
              ...formData,
              city: code,
              district: "",
              village: "",
            });
            fetchDistricts(code);
          }}
          disabled={!formData.province}
          renderInput={(params) => (
            <TextField {...params} label="City" fullWidth margin="normal" />
          )}
        />
        <Autocomplete
          options={districts}
          getOptionLabel={(option) => option.name}
          value={districts.find((d) => d.code === formData.district) || null}
          onChange={(event, newValue) => {
            const code = newValue?.code || "";
            setFormData({
              ...formData,
              district: code,
              village: "",
            });
            fetchVillages(code);
          }}
          disabled={!formData.city}
          renderInput={(params) => (
            <TextField {...params} label="District" fullWidth margin="normal" />
          )}
        />
        <Autocomplete
          options={villages}
          getOptionLabel={(option) => option.name}
          value={villages.find((v) => v.code === formData.village) || null}
          onChange={(event, newValue) => {
            const code = newValue?.code || "";
            setFormData({
              ...formData,
              village: code,
              location_code: code,
            });
          }}
          disabled={!formData.district}
          renderInput={(params) => (
            <TextField {...params} label="Village" fullWidth margin="normal" />
          )}
        />
        <TextField
          label="Latitude"
          fullWidth
          margin="normal"
          value={formData.latitude}
          autoComplete="off"
          type="number"
          onChange={(e) => {
            const value = e.target.value;
            const correctedValue = value.replace(",", ".");
            setFormData({ ...formData, latitude: correctedValue });
          }}
          error={formData.latitude.includes(",")}
          helperText={
            formData.latitude.includes(",") &&
            "Please use a dot (.) instead of a comma (,)."
          }
        />
        <TextField
          label="Longitude"
          fullWidth
          margin="normal"
          value={formData.longitude}
          autoComplete="off"
          type="number"
          onChange={(e) => {
            const value = e.target.value;
            const correctedValue = value.replace(",", ".");
            setFormData({ ...formData, longitude: correctedValue });
          }}
          error={formData.longitude.includes(",")}
          helperText={
            formData.longitude.includes(",") &&
            "Please use a dot (.) instead of a comma (,)."
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
