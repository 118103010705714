import Typography from "@mui/material/Typography";
import * as React from "react";
import { API_URL } from "../../../config/apiConfig";

export default function Copyright(props) {
  const [appName, setAppName] = React.useState("APP_NAME");
  const [appVersion, setAppVersion] = React.useState("APP_VERSION");

  const getAppAndVersion = async () => {
    try {
      const response = await fetch(`${API_URL}/v1/app-settings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch app settings");
      }

      const result = await response.json();
      if (result.status && Array.isArray(result.data)) {
        const settings = Object.fromEntries(
          result.data.map((item) => [item.key, item.value])
        );

        setAppName(settings.site_name || "APP_NAME");
        setAppVersion(settings.app_version || "APP_VERSION");
      }
    } catch (error) {
      console.error("Error fetching app settings:", error);
    }
  };

  React.useEffect(() => {
    getAppAndVersion();
  }, []);

  return (
    <Typography
      variant="body2"
      align="center"
      {...props}
      sx={[
        {
          color: "text.secondary",
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      {"Copyright © "}
      Chaidar Aria {new Date().getFullYear()} <br />
      {appName} {appVersion}
    </Typography>
  );
}
