import { Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Circle, MapContainer, Popup, TileLayer } from "react-leaflet";
import { getColorForMmiLevel } from "../../utils/mmiColors";

export function MmiDataMap({ earthquake, token, apiUrl }) {
  const [mmiData, setMmiData] = useState(null);
  const [mmiError, setMmiError] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [apiKeyError, setApiKeyError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch API Key
  const fetchApiData = async () => {
    setLoading(true);
    setApiKeyError(null);

    try {
      const keyResponse = await fetch(`${apiUrl}/v1/api-keys/my-api-keys`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!keyResponse.ok)
        throw new Error(`HTTP error! status: ${keyResponse.status}`);

      const keyData = await keyResponse.json();
      if (keyData.responseCode !== 200) {
        setApiKey(null);
        setApiKeyError("API Key not found");
      } else {
        setApiKey(keyData.data.api_key);
      }
    } catch (err) {
      setApiKey(null);
      setApiKeyError("Failed to fetch API Key");
    }
  };

  // Fetch MMI Data
  const fetchMmiData = useCallback(async () => {
    if (!earthquake) return;

    if (!apiKey) {
      setMmiError("MMI data cannot be fetched due to missing API Key");
      setMmiData(null);
      return;
    }

    try {
      const mmiResponse = await fetch(
        `${apiUrl}/v1/earthquakes/mmi/${earthquake.eventId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apiKey,
          },
          body: JSON.stringify({
            lat: earthquake.latitude,
            lon: earthquake.longitude,
          }),
        }
      );

      if (!mmiResponse.ok) throw new Error("Failed to fetch MMI data");

      const mmiDataResponse = await mmiResponse.json();
      if (mmiDataResponse.responseCode !== 200)
        throw new Error(mmiDataResponse.message);

      const groupedData = mmiDataResponse.data.reduce((acc, item) => {
        const mmiLevel = item.mmi_level;
        if (!acc[mmiLevel]) acc[mmiLevel] = [];
        acc[mmiLevel].push({
          latitude: item.latitude,
          longitude: item.longitude,
        });
        return acc;
      }, {});

      setMmiData(groupedData);
      setMmiError(null);
    } catch (err) {
      setMmiError(err.message);
      setMmiData(null);
    } finally {
      setLoading(false);
    }
  }, [apiKey, earthquake]);

  useEffect(() => {
    fetchApiData();
  }, [token, apiUrl]);

  useEffect(() => {
    fetchMmiData();
  }, [fetchMmiData]);

  return (
    <>
      {/* MMI Data Section */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        MMI Data
      </Typography>

      {earthquake && !apiKey ? (
        <Typography color="error" fontWeight="bold">
          API Key not found - MMI Map cannot be displayed
        </Typography>
      ) : (
        <MapContainer
          center={[earthquake.latitude, earthquake.longitude]}
          zoom={8.5}
          style={{ height: "500px", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          {mmiError ? (
            <Popup position={[earthquake.latitude, earthquake.longitude]}>
              <strong style={{ color: "red" }}>Error:</strong> {mmiError}
            </Popup>
          ) : (
            mmiData &&
            Object.entries(mmiData).map(([mmiLevel, locations], index) =>
              locations.map((loc, locIndex) => (
                <Circle
                  key={locIndex}
                  center={[loc.latitude, loc.longitude]}
                  radius={2000}
                  pathOptions={{
                    fillColor: getColorForMmiLevel(mmiLevel),
                    color: getColorForMmiLevel(mmiLevel),
                    weight: 1,
                    fillOpacity: 0.5,
                  }}
                >
                  <Popup>
                    <strong>MMI Level: {mmiLevel}</strong>
                    <br />
                    Latitude: {loc.latitude}
                    <br />
                    Longitude: {loc.longitude}
                  </Popup>
                </Circle>
              ))
            )
          )}
        </MapContainer>
      )}

      {/* MMI Legend Section */}
      <Typography component="h2" variant="h6" sx={{ mt: 2 }}>
        MMI Scale Legend
      </Typography>
      <img
        src="https://usgs.github.io/shakemap/_images/mmi_scale_top.png"
        alt="MMI Scale Legend"
        style={{ width: "100%", maxWidth: "600px", marginTop: "10px" }}
      />
    </>
  );
}
