import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, FormControl, FormLabel, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Swal from "sweetalert2";
import { API_URL } from "../../config/apiConfig";

export default function CaptchaField({
  captchaInput,
  setCaptchaInput,
  captchaKey,
  setCaptchaKey,
  error,
  errorMessage,
}) {
  const [captchaImage, setCaptchaImage] = useState("");

  // Gunakan useCallback agar fungsi tidak berubah di setiap render
  const handleCaptchaLoad = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/v1/auth/captcha`);
      const data = await response.json();

      if (response.ok) {
        setCaptchaKey(data.data.captchaKey);
        setCaptchaImage(data.data.captchaImage);
        setCaptchaInput(""); // Reset input saat captcha di-refresh
      } else {
        throw new Error("Failed to load CAPTCHA");
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
      });
    }
  }, [setCaptchaKey, setCaptchaInput]);

  useEffect(() => {
    handleCaptchaLoad();
  }, [handleCaptchaLoad]); // Masukkan ke dependency array

  useEffect(() => {
    if (error) {
      handleCaptchaLoad();
    }
  }, [error, handleCaptchaLoad]); // Pastikan fungsi ini tetap sama

  return (
    <FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <FormLabel htmlFor="Captcha">Captcha</FormLabel>
        <Button
          onClick={handleCaptchaLoad}
          size="small"
          variant="contained"
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            backgroundColor: "hsl(210, 90%, 40%)",
            "&:hover": { backgroundColor: "hsl(210, 80%, 30%)" },
          }}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
      </Box>
      {captchaImage && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            padding: "8px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img src={captchaImage} alt="CAPTCHA" style={{ maxWidth: "100%" }} />
        </Box>
      )}
      <TextField
        error={error}
        helperText={errorMessage}
        id="captchaInput"
        name="captchaInput"
        type="text"
        placeholder="Enter CAPTCHA"
        required
        fullWidth
        variant="outlined"
        value={captchaInput.toUpperCase()}
        onChange={(e) => setCaptchaInput(e.target.value.toUpperCase())}
        inputProps={{ style: { textTransform: "uppercase" } }}
      />
    </FormControl>
  );
}
