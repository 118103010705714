import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Skeleton,
} from "@mui/material";

export function DetailEarthquakeInformation({ earthquakeId, apiUrl }) {
  const [earthquake, setEarthquake] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEarthquakeDetails = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/v1/earthquakes/${earthquakeId}`
        );
        if (!response.ok) throw new Error("Failed to fetch earthquake details");

        const data = await response.json();
        setEarthquake(data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEarthquakeDetails();
  }, [earthquakeId, apiUrl]);

  return (
    <Box sx={{ flex: 1 }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Earthquake Information
      </Typography>
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height={300} />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : earthquake ? (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>Event ID</strong>
                </TableCell>
                <TableCell>{earthquake.eventId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Date</strong>
                </TableCell>
                <TableCell>
                  {new Date(earthquake.date).toLocaleDateString("id-ID")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Time</strong>
                </TableCell>
                <TableCell>{earthquake.time}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Latitude</strong>
                </TableCell>
                <TableCell>{earthquake.latitude}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Longitude</strong>
                </TableCell>
                <TableCell>{earthquake.longitude}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Magnitude</strong>
                </TableCell>
                <TableCell>{earthquake.magnitude}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Depth</strong>
                </TableCell>
                <TableCell>{earthquake.depth} km</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Location</strong>
                </TableCell>
                <TableCell>{earthquake.location}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography color="error">No data available</Typography>
      )}
    </Box>
  );
}
