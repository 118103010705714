import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServerError = ({ retryCheck }) => {
  const [countdown, setCountdown] = useState(10);
  const [isServerOnline, setIsServerOnline] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 10));
    }, 1000);

    const checkServer = async () => {
      const online = await retryCheck();
      if (online) {
        setIsServerOnline(true);
      } else {
        setTimeout(checkServer, 5000); // Coba lagi setelah 5 detik jika masih offline
      }
    };

    const timeout = setTimeout(checkServer, 10000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [retryCheck]);

  useEffect(() => {
    if (isServerOnline) {
      window.location.reload(); // Reload saat server kembali normal
    }
  }, [isServerOnline]);

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="text-4xl font-bold text-red-600">
        500 - Internal Server Error
      </h1>
      <p className="mt-2 text-gray-600">
        {isServerOnline
          ? "Server is back online! Reloading..."
          : `An error occurred on the server. Retrying in ${countdown} seconds...`}
      </p>
      <Link to="/" className="mt-4 text-blue-500 underline">
        Back to Home
      </Link>
    </div>
  );
};

export default ServerError;
