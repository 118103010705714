import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";

const AdminLayout = () => {
  return (
    <>
      {/* ToastContainer hanya di halaman admin */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
        transition={Slide}
      />

      {/* Tempat halaman admin di-render */}
      <Outlet />
    </>
  );
};

export default AdminLayout;
