import { jwtDecode } from "jwt-decode"; // Gunakan named import

export function getUserRole() {
  const token = localStorage.getItem("token");

  if (!token) return null;
  try {
    const decoded = jwtDecode(token);
    return decoded.role || null;
  } catch (err) {
    console.error("Invalid token", err);
    return null;
  }
}
