import { Autocomplete, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { API_URL } from "../../config/apiConfig";

export default function AiModelGrid() {
  const [rlModels, setRlModels] = useState(null);
  const [knnModels, setKnnModels] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [eventId, setEventId] = useState("");
  const [eventList, setEventList] = useState([]);
  const token = localStorage.getItem("token");

  const fetchModels = useCallback(
    async (type, setter) => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/v1/ai/${type}/getModel`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) throw new Error(`Failed to fetch ${type} models`);
        const data = await response.json();
        setter(data.data || { primaryModel: null, backupModel: null });
      } catch (error) {
        console.error(`Error fetching ${type} models:`, error);
      }
      setLoading(false);
    },
    [token]
  );

  useEffect(() => {
    fetchModels("RL", setRlModels);
    fetchModels("KNN", setKnnModels);
  }, [fetchModels]);

  const handleUpload = async () => {
    if (!selectedFile || !uploadType) return;
    setUploading(true);
    try {
      const formData = new FormData();
      formData.append("model", selectedFile);
      const response = await fetch(
        `${API_URL}/v1/ai/${uploadType}/uploadModel`,
        {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        }
      );
      if (!response.ok) throw new Error(`Failed to upload ${uploadType} model`);
      setOpen(false);
      fetchModels(uploadType, uploadType === "RL" ? setRlModels : setKnnModels);
      Swal.fire("Success", "Model uploaded successfully!", "success");
    } catch (error) {
      Swal.fire("Error", `Failed to upload ${uploadType} model`, "error");
    }
    setUploading(false);
  };

  const handleDelete = async (type, id) => {
    if (type === "RL" && !knnModels?.primaryModel && !knnModels?.backupModel) {
      Swal.fire(
        "Error",
        "Cannot delete RL model as no KNN model exists.",
        "error"
      );
      return;
    }
    if (type === "KNN" && !rlModels?.primaryModel) {
      Swal.fire(
        "Error",
        "Cannot delete KNN model as no primary RL model exists.",
        "error"
      );
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `${API_URL}/v1/ai/${type}/deleteModel/${id}`,
            {
              method: "DELETE",
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (!response.ok) throw new Error(`Failed to delete ${type} model`);

          // Perbarui state tanpa reload
          if (type === "RL") {
            setRlModels((prev) => ({
              primaryModel:
                prev.primaryModel?.id === id ? null : prev.primaryModel,
              backupModel:
                prev.backupModel?.id === id ? null : prev.backupModel,
            }));
          } else {
            setKnnModels((prev) => ({
              primaryModel:
                prev.primaryModel?.id === id ? null : prev.primaryModel,
              backupModel:
                prev.backupModel?.id === id ? null : prev.backupModel,
            }));
          }

          Swal.fire("Deleted!", "Your model has been deleted.", "success");
        } catch (error) {
          Swal.fire("Error", `Failed to delete ${type} model`, "error");
        }
      }
    });
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/earthquakes`);
        if (!response.ok) throw new Error("Failed to fetch events");

        const data = await response.json();

        // Urutkan berdasarkan tanggal terbaru ke terlama (Saat ditambahkan)
        // Menggunakan new Date() untuk mengonversi string tanggal ke objek Date
        const sortedData = data.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setEventList(sortedData);
      } catch (error) {
        Swal.fire("Error", "Failed to fetch earthquake events", "error");
      }
    };

    fetchEvents();
  }, []);

  const handleDownloadDataset = async () => {
    try {
      const response = await fetch(`${API_URL}/v1/dataset/export-dataset`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error("Failed to fetch dataset path");
      const data = await response.json();
      const filePath = data.data.filePath;
      const fileName = filePath.split("/").pop();

      // Ambil file dari server
      const fileResponse = await fetch(`${API_URL}/v1${filePath}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!fileResponse.ok) throw new Error("Failed to fetch file");

      // Konversi ke Blob
      const blob = await fileResponse.blob();
      const downloadUrl = URL.createObjectURL(blob);

      // Buat link untuk mengunduh file
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute("download", fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Hapus elemen setelah download
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      Swal.fire("Error", "Failed to download dataset", "error");
    }
  };

  // Fungsi untuk mendownload dataset berdasarkan eventId yang dipilih
  const handleDownloadDatasetPerEvent = async () => {
    if (!eventId) {
      Swal.fire("Error", "Please select an event ID", "warning");
      return;
    }

    try {
      const response = await fetch(
        `${API_URL}/v1/dataset/export-dataset/${eventId}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch dataset path");

      const data = await response.json();
      const filePath = data.data.filePath;
      const fileName = filePath.split("/").pop();

      const fileResponse = await fetch(`${API_URL}/v1${filePath}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!fileResponse.ok) throw new Error("Failed to fetch file");

      const blob = await fileResponse.blob();
      const downloadUrl = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute("download", fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      Swal.fire("Error", "Failed to download dataset", "error");
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ width: "100%", maxWidth: "1700px" }}>
      {/* Upload Buttons */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(true);
          setUploadType("RL");
        }}
      >
        Upload RL Model
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(true);
          setUploadType("KNN");
        }}
      >
        Upload KNN Model
      </Button>
      {/* Models Table */}
      {[
        { models: rlModels, type: "RL" },
        { models: knnModels, type: "KNN" },
      ].map(({ models, type }) => (
        <TableContainer component={Paper} sx={{ mt: 3 }} key={type}>
          <Typography variant="h6" sx={{ p: 2 }}>
            {type} Models
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Model Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!models?.primaryModel && !models?.backupModel ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No models available
                  </TableCell>
                </TableRow>
              ) : (
                [models?.primaryModel, models?.backupModel]
                  .filter(Boolean)
                  .map((model) => (
                    <TableRow key={model.id}>
                      <TableCell>{model.name}</TableCell>
                      <TableCell>{model.model}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDelete(type, model.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                      <TableCell>
                        {new Date(model.created_at).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
      {/* Download Dataset Button */}
      <Box
        sx={{ mt: 4, p: 3, borderRadius: 2, boxShadow: 2}}
      >
        <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
          Download Dataset
        </Typography>

        <Grid container spacing={2} alignItems="center">
          {/* Tombol Download Semua Event */}
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleDownloadDataset}
            >
              Download All Dataset
            </Button>
          </Grid>

          {/* Dropdown Pilihan Event */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={eventList}
              getOptionLabel={(event) =>
                `${event.eventId} - ${event.location} (${new Date(
                  event.date
                ).toLocaleDateString()})`
              }
              renderInput={(params) => (
                <TextField {...params} label="Select Event ID" fullWidth />
              )}
              value={
                eventList.find((event) => event.eventId === eventId) || null
              }
              onChange={(event, newValue) =>
                setEventId(newValue ? newValue.eventId : "")
              }
            />
          </Grid>

          {/* Tombol Download untuk Event Terpilih */}
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleDownloadDatasetPerEvent}
              disabled={!eventId} // Tombol disable jika belum memilih event
            >
              Download Selected Event
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* Upload Modal */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            p: 4,
            bgcolor: "background.paper",
            borderRadius: 2,
            width: 400,
            mx: "auto",
            mt: 10,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Upload {uploadType} Model
          </Typography>
          <input
            type="file"
            accept=".zip"
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={uploading || !selectedFile}
            onClick={handleUpload}
            sx={{ mt: 2 }}
          >
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
