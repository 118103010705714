const isProduction = window.location.hostname !== "localhost";

export const API_URL = isProduction
  ? "https://api.adagempa.cloud"
  : "http://localhost:3005";

export const SHAKEMAP_API_URL = isProduction
  ? "https://shakemap.adagempa.cloud"
  : "http://localhost:3002";

export const OSRM_API_URL = isProduction
  ? "https://osrm.adagempa.cloud"
  : "http://localhost:3006";