import React from "react";
import { Link } from "react-router-dom";

const Forbidden = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="text-4xl font-bold text-yellow-500">403 - Forbidden</h1>
      <p className="mt-2 text-gray-600">You do not have access to this page.</p>
      <Link to="/" className="mt-4 text-blue-500 underline">
        Back to Home
      </Link>
    </div>
  );
};

export default Forbidden;
