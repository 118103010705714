import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL, SHAKEMAP_API_URL } from "../../config/apiConfig";
import { DetailEarthquakeInformation } from "./DetailEarthquakeInformation";
import { GmpeEarthquake } from "./GmpeEarthquake";
import { IntensityMapImage } from "./IntensityMapImage";
import { MmiDataMap } from "./MmiDataMap";
import { StrecEarthquake } from "./StrecEarthquake";

export default function DetailEarthquakeGrid() {
  const { id } = useParams();
  const [earthquake, setEarthquake] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchEarthquakeDetailsData = async () => {
      try {
        const earthquakeResponse = await fetch(
          `${API_URL}/v1/earthquakes/${id}`
        );
        if (!earthquakeResponse.ok)
          throw new Error("Failed to fetch earthquake details");

        const earthquakeData = await earthquakeResponse.json();
        setEarthquake(earthquakeData.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEarthquakeDetailsData(id);
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        p: 4,
      }}
    >
      {/* Title */}
      <Typography component="h1" variant="h4" sx={{ mb: 4 }}>
        Earthquake Event Details
      </Typography>
      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 4,
        }}
      >
        {/* Earthquake Information Table */}
        <DetailEarthquakeInformation earthquakeId={id} apiUrl={API_URL} />

        {/* MMI Map */}
        <IntensityMapImage
          earthquakeId={earthquake?.eventId}
          shakemapApiUrl={SHAKEMAP_API_URL}
        />
      </Box>

      {/* GMPEs Data Section */}
      <GmpeEarthquake
        eventId={earthquake?.eventId}
        token={token}
        apiUrl={API_URL}
      />

      {/* STREC Data Section */}
      <StrecEarthquake
        eventId={earthquake?.eventId}
        token={token}
        apiUrl={SHAKEMAP_API_URL}
      />

      {/* MMI Data Section */}
      <MmiDataMap earthquake={earthquake} token={token} apiUrl={API_URL} />
    </Box>
  );
}
