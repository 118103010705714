import { useState } from "react";
import { Box, Typography } from "@mui/material";

export function IntensityMapImage({ earthquakeId, shakemapApiUrl }) {
  const [imageError, setImageError] = useState(false);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {earthquakeId && !imageError ? (
        <img
          src={`${shakemapApiUrl}/v1/shakemap/intensity/${earthquakeId}`}
          alt="MMI Map"
          style={{ maxWidth: "100%", height: "auto", borderRadius: 8 }}
          onError={() => setImageError(true)}
        />
      ) : (
        <Typography color="error" fontWeight="bold">
          MMI Map could not be loaded
        </Typography>
      )}
    </Box>
  );
}
