import {
  Box,
  Button,
  CssBaseline,
  Link,
  Card as MuiCard,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL } from "../../config/apiConfig";
import Copyright from "../../Dashboard/internals/components/Copyright";
import AppTheme from "../../shared-theme/AppTheme";
import ColorModeSelect from "../../shared-theme/ColorModeSelect";
import CaptchaField from "../components/CaptchaField";
import EmailField from "../components/EmailField";
import PasswordField from "../components/PasswordField";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

export default function SignIn(props) {
  const navigate = useNavigate();
  const [captchaKey, setCaptchaKey] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaError, setCaptchaError] = useState(false);
  const [captchaErrorMessage, setCaptchaErrorMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateInputs = (email, password, captchaInput) => {
    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    if (!captchaInput || captchaInput.length === 0) {
      setCaptchaError(true);
      setCaptchaErrorMessage("Please enter the CAPTCHA.");
      isValid = false;
    } else {
      setCaptchaError(false);
      setCaptchaErrorMessage("");
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;
    const captchaInputValue = event.target.captchaInput.value.toUpperCase();

    if (!validateInputs(email, password, captchaInputValue)) {
      return;
    }

    try {
      // Proceed with login request after CAPTCHA verification
      const loginResponse = await fetch(`${API_URL}/v1/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          captchaKey,
          captchaInput: captchaInputValue,
        }),
      });

      if (!loginResponse.ok) {
        const errorData = await loginResponse.json();
        throw new Error(errorData.message || "Authentication failed.");
      }

      const data = await loginResponse.json();

      if (data.data.token) {
        localStorage.setItem("token", data.data.token);
        Swal.fire({
          title: "Success!",
          text: "Authentication successful.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/admin");
      } else {
        throw new Error("Token not received, authentication failed.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      Swal.fire({
        title: "Failed!",
        text: error.message,
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <ColorModeSelect
          sx={{ position: "fixed", top: "1rem", right: "1rem" }}
        />
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{
              width: "100%",
              fontSize: "clamp(2rem, 10vw, 2.15rem)",
              textAlign: "center",
            }}
          >
            Ada Gempa Dashboard Authentication
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <EmailField
              email={email}
              setEmail={setEmail}
              error={emailError}
              errorMessage={emailErrorMessage}
            />
            <PasswordField
              password={password}
              setPassword={setPassword}
              error={passwordError}
              errorMessage={passwordErrorMessage}
            />
            <CaptchaField
              captchaInput={captchaInput}
              setCaptchaInput={setCaptchaInput}
              captchaKey={captchaKey}
              setCaptchaKey={setCaptchaKey}
              error={captchaError}
              errorMessage={captchaErrorMessage}
            />
            <Button type="submit" fullWidth variant="contained">
              Sign in
            </Button>
            <Typography sx={{ textAlign: "center" }}>
              Don't have an account?{" "}
              <Link
                component="button"
                onClick={() => navigate("/auth/sign-up")}
                variant="body2"
                sx={{ cursor: "pointer" }}
              >
                Sign up
              </Link>
            </Typography>
            {/* <Link
              component="button"
              onClick={handleClickOpen}
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Forgot your password?
            </Link> */}
          </Box>
          <Copyright sx={{ my: 1 }} />
        </Card>
      </SignInContainer>
    </AppTheme>
  );
}
