import { FormControl, FormLabel, TextField } from "@mui/material";
import React from "react";

export default function EmailField({ email, setEmail, error, errorMessage }) {
  return (
    <FormControl>
      <FormLabel htmlFor="email">Email</FormLabel>
      <TextField
        error={error}
        helperText={errorMessage}
        id="email"
        type="email"
        name="email"
        placeholder="your@email.com"
        autoComplete="off"
        required
        fullWidth
        variant="outlined"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </FormControl>
  );
}
