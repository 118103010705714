import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import L from "leaflet";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import Swal from "sweetalert2";
import { API_URL, OSRM_API_URL } from "../../config/apiConfig";
import {
  loadFromSessionStorage,
  saveToSessionStorage,
} from "../../utils/storageUtils";
import Copyright from "../internals/components/Copyright";

const startMarkerIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const endMarkerIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function MapComponent({ result }) {
  const map = useMap();
  const [routeCoordinates, setRouteCoordinates] = useState([]);

  useEffect(() => {
    if (result?.data?.DeptLatitude && result?.data?.DestLatitude) {
      const startPoint = [
        parseFloat(result.data.DeptLatitude),
        parseFloat(result.data.DeptLongitude),
      ];
      const endPoint = [
        parseFloat(result.data.DestLatitude),
        parseFloat(result.data.DestLongitude),
      ];

      const bounds = L.latLngBounds([startPoint, endPoint]);
      map.fitBounds(bounds, { padding: [50, 50] });

      // Tambahkan marker untuk titik awal dan tujuan
      L.marker(startPoint, { icon: startMarkerIcon })
        .addTo(map)
        .bindPopup("<b>Start Point</b>")
        .openPopup();

      L.marker(endPoint, { icon: endMarkerIcon })
        .addTo(map)
        .bindPopup("<b>Finish Point</b>")
        .openPopup();

      // **Panggil OSRM untuk mendapatkan rute**
      const fetchRoute = async () => {
        const osrmUrl = `${OSRM_API_URL}/route/v1/driving/${startPoint[1]},${startPoint[0]};${endPoint[1]},${endPoint[0]}?overview=full&geometries=geojson`;

        try {
          const response = await fetch(osrmUrl);
          const data = await response.json();

          if (data.routes && data.routes.length > 0) {
            const coordinates = data.routes[0].geometry.coordinates.map(
              (coord) => [coord[1], coord[0]]
            );
            setRouteCoordinates(coordinates);

            // Tambahkan polyline ke peta
            L.polyline(coordinates, { color: "blue", weight: 5 }).addTo(map);
          }
        } catch (error) {
          console.error("Error fetching route from OSRM:", error);
        }
      };

      fetchRoute();
    }
  }, [result, map]);

  return null;
}

export default function EvacuationSimulationGrid() {
  const [apiKey, setApiKey] = useState("");
  const [userLocationLat, setUserLocationLat] = useState("");
  const [userLocationLon, setUserLocationLon] = useState("");
  const [result, setResult] = useState(null);
  const [loadingEvacuationRoute, setLoadingEvacuationRoute] = useState(false);
  const token = localStorage.getItem("token");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [isSimulating, setIsSimulating] = useState(false);

  useEffect(() => {
    const storedResult = loadFromSessionStorage();
    if (storedResult) {
      setResult(storedResult);
    }
  }, []);

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/api-keys/my-api-keys`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.responseCode === 200) {
          setApiKey(data.data.api_key);
        } else {
          setApiKey("No API key found. Please generate a new one");
        }
      } catch (err) {
        setApiKey("No API key found. Please generate a new one");
      }
    };

    fetchApiKey();
  }, [token]);

  useEffect(() => {
    const fetchAllLocations = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/admin/locations/all`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) throw new Error("Failed to fetch locations");

        const result = await response.json();

        if (result.responseCode !== 200) {
          throw new Error(result.message || "Failed to fetch locations");
        }

        const allLocations = result.data;

        // Filter locations based on the number of dots in the 'code' field
        const provinces = allLocations.filter(
          (item) => item.code.split(".").length === 1
        );
        const cities = allLocations.filter(
          (item) => item.code.split(".").length === 2
        );
        const districts = allLocations.filter(
          (item) => item.code.split(".").length === 3
        );
        const villages = allLocations.filter(
          (item) => item.code.split(".").length === 4
        );

        setProvinces(provinces);
        setCities(cities);
        setDistricts(districts);
        setVillages(villages);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchAllLocations();
  }, [token]);

  const handleSimulateEvacuationRoute = async () => {
    if (!apiKey || apiKey === "No API key found. Please generate a new one") {
      Swal.fire("Error", "API Key is required.", "error");
      return;
    }

    if (!userLocationLat || !userLocationLon) {
      Swal.fire("Error", "Latitude and Longitude are required.", "error");
      return;
    }

    try {
      setIsSimulating(true);
      setLoadingEvacuationRoute(true);
      const response = await fetch(`${API_URL}/v1/evacuation-points/select`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${apiKey}`,
        },
        body: JSON.stringify({
          latitude: parseFloat(userLocationLat),
          longitude: parseFloat(userLocationLon),
        }),
      });

      const responseData = await response.json();

      if (response.ok && responseData.responseCode === 200) {
        const data = responseData.data || {};
        const selectedPoint = data.selectedPoint || {};

        if (!selectedPoint || !selectedPoint.location?.code) {
          throw new Error("Invalid response structure: missing location code");
        }

        const locationCode = selectedPoint.location?.code || "";
        const [provinceCode, cityCode, districtCode, villageCode] =
          locationCode.split(".");

        const province = provinces.find(
          (item) => item.code === provinceCode
        ) || { name: "Unknown Province" };
        const city = cities.find(
          (item) => item.code === `${provinceCode}.${cityCode}`
        ) || { name: "Unknown City" };
        const district = districts.find(
          (item) => item.code === `${provinceCode}.${cityCode}.${districtCode}`
        ) || { name: "Unknown District" };
        const village = villages.find(
          (item) =>
            item.code ===
            `${provinceCode}.${cityCode}.${districtCode}.${villageCode}`
        ) || { name: "Unknown Village" };

        const newResult = {
          status: "success",
          data: {
            realDistance: selectedPoint.realDistance
              ? (selectedPoint.realDistance / 1000).toFixed(2)
              : "N/A",
            distance: selectedPoint.distance
              ? selectedPoint.distance.toFixed(2)
              : "N/A",
            DeptLatitude: selectedPoint.DeptLatitude || "N/A",
            DeptLongitude: selectedPoint.DeptLongitude || "N/A",
            DestLatitude: selectedPoint.DestLatitude || "N/A",
            DestLongitude: selectedPoint.DestLongitude || "N/A",
            locationCode: selectedPoint.location_code || "N/A",
            hierarchy: {
              province: province.name,
              city: city.name,
              district: district.name,
              village: village.name,
            },
            executionTime: {
              total: data.executionTime?.total
                ? `${data.executionTime.total} ms / ${(
                    data.executionTime.total / 1000
                  ).toFixed(3)} s`
                : "N/A",
              fetchEvent: data.executionTime?.fetchEvent
                ? `${data.executionTime.fetchEvent} ms / ${(
                    data.executionTime.fetchEvent / 1000
                  ).toFixed(3)} s`
                : "N/A",
              rl: data.executionTime?.rl
                ? `${data.executionTime.rl} ms / ${(
                    data.executionTime.rl / 1000
                  ).toFixed(3)} s`
                : "N/A",
              knn: data.executionTime?.knn
                ? `${data.executionTime.knn} ms / ${(
                    data.executionTime.knn / 1000
                  ).toFixed(3)} s`
                : "N/A",
              osrm: data.executionTime?.osrm
                ? `${data.executionTime.osrm} ms / ${(
                    data.executionTime.osrm / 1000
                  ).toFixed(3)} s`
                : "N/A",
            },
          },
        };

        setResult(newResult);
        saveToSessionStorage(newResult); // Simpan ke sessionStorage
      } else {
        setResult({
          status: "error",
          data: {
            message: responseData.message || "An unknown error occurred.",
          },
        });
      }
    } catch (error) {
      setResult({
        status: "error",
        data: { message: error.message || "An unknown error occurred." },
      });
    } finally {
      setLoadingEvacuationRoute(false);
      setIsSimulating(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 4, mt: 4 }}>
        {/* All Forms */}
        <Box sx={{ flex: 1, border: "1px solid #ccc", borderRadius: 2, p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Evacuation Route Simulation
          </Typography>

          <TextField
            fullWidth
            label="API KEY"
            value={apiKey || "No API key found. Please generate a new one"}
            autoComplete="off"
            type="text"
            disabled
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="Latitude"
            value={userLocationLat}
            autoComplete="off"
            type="number"
            onChange={(e) => setUserLocationLat(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Longitude"
            value={userLocationLon}
            autoComplete="off"
            type="number"
            onChange={(e) => setUserLocationLon(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSimulateEvacuationRoute}
            disabled={loadingEvacuationRoute || isSimulating}
          >
            {loadingEvacuationRoute ? (
              <CircularProgress size={24} />
            ) : (
              "Simulate Evacuation Route"
            )}
          </Button>
        </Box>

        {/* Result Box */}
        <Box
          sx={{
            flex: 1,
            border: "1px solid #ccc",
            borderRadius: 2,
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Simulation Results
          </Typography>
          {result ? (
            result.status === "error" ? (
              <Typography color="error">{result.data.message}</Typography>
            ) : (
              <>
                <Typography variant="h6">Evacuation Route Info</Typography>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Real Distance (OSRM)</TableCell>
                      <TableCell align="right">
                        {result.data.realDistance} km
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Distance (Haversine)</TableCell>
                      <TableCell align="right">
                        {result.data.distance} km
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Departure Latitude</TableCell>
                      <TableCell align="right">
                        {result.data.DeptLatitude}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Departure Longitude</TableCell>
                      <TableCell align="right">
                        {result.data.DeptLongitude}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Destination Latitude</TableCell>
                      <TableCell align="right">
                        {result.data.DestLatitude}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Destination Longitude</TableCell>
                      <TableCell align="right">
                        {result.data.DestLongitude}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Typography variant="h6" sx={{ mt: 2 }}>
                  Location Hierarchy
                </Typography>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Location Code</TableCell>
                      <TableCell align="right">
                        {result.data.locationCode}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Province</TableCell>
                      <TableCell align="right">
                        {result.data.hierarchy.province}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>City</TableCell>
                      <TableCell align="right">
                        {result.data.hierarchy.city}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>District</TableCell>
                      <TableCell align="right">
                        {result.data.hierarchy.district}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Village</TableCell>
                      <TableCell align="right">
                        {result.data.hierarchy.village}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Typography variant="h6" sx={{ mt: 2 }}>
                  Execution Time
                </Typography>
                <Table size="small">
                  <TableBody>
                    {["total", "fetchEvent", "rl", "knn", "osrm"].map((key) => {
                      const formattedKey =
                        {
                          total: "Total",
                          fetchEvent: "Fetch Event",
                          rl: "RL",
                          knn: "KNN",
                          osrm: "OSRM",
                        }[key] || key;

                      return (
                        <TableRow key={key}>
                          <TableCell>{formattedKey}</TableCell>
                          <TableCell align="right">
                            {result.data.executionTime?.[key]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                <Typography
                  variant="body2"
                  sx={{ mt: 2, fontStyle: "italic", color: "gray" }}
                >
                  Routing data provided by{" "}
                  <a
                    href="http://project-osrm.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OSRM
                  </a>
                  .
                </Typography>
              </>
            )
          ) : (
            <Typography variant="body1">No results to display.</Typography>
          )}
        </Box>
      </Box>

      {/* Map Section */}
      {result && result.status === "success" && (
        <Box sx={{ mt: 4, height: "400px" }}>
          <MapContainer
            center={[0, 0]}
            zoom={14}
            style={{ height: "400px", width: "100%", marginTop: "10px" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {result && <MapComponent result={result} />}
          </MapContainer>
        </Box>
      )}

      <Copyright sx={{ mt: 4 }} />
    </Box>
  );
}
