import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { CircleMarker, MapContainer, Popup, TileLayer } from "react-leaflet";
import { API_URL } from "../../config/apiConfig";
import { showToast } from "../../utils/toastUtils";

export default function EarthquakeDistributionMaps() {
  const [earthquakes, setEarthquakes] = useState([]);
  const [mapCenter, setMapCenter] = useState([-2.5489, 118.0149]); // Default: Indonesia
  const [loading, setLoading] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  useEffect(() => {
    const fetchEarthquakes = async () => {
      setLoading(true);
      showToast("info", "Mengambil data gempa...", { autoClose: 5000 });

      try {
        // Tunggu 5-10 detik sebelum memproses hasilnya
        await new Promise((resolve) =>
          setTimeout(resolve, Math.random() * (10000 - 5000) + 5000)
        );

        const response = await fetch(`${API_URL}/v1/earthquakes`);
        if (!response.ok) throw new Error("Gagal mengambil data gempa");

        const data = await response.json();
        const now = new Date();
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(now.getFullYear() - 1);

        // Filter hanya gempa dalam 1 tahun terakhir
        const recentEarthquakes = data.data.filter((quake) => {
          const quakeDate = new Date(quake.date);
          return quakeDate >= oneYearAgo;
        });

        if (recentEarthquakes.length === 0) {
          throw new Error("Tidak ada data gempa terbaru untuk ditampilkan");
        }

        setEarthquakes(recentEarthquakes);
        setIsMapLoaded(true);

        // Jika ada gempa dalam 1 tahun terakhir, fokus ke lokasi pertama
        setMapCenter([
          recentEarthquakes[0].latitude,
          recentEarthquakes[0].longitude,
        ]);
      } catch (error) {
        console.error("Error fetching earthquake data:", error);
        showToast("error", error.message, { autoClose: 3000 });
        setIsMapLoaded(false);
      } finally {
        setLoading(false);
      }
    };

    fetchEarthquakes();
  }, []);

  useEffect(() => {
    if (isMapLoaded) {
      showToast("success", "Data gempa berhasil dimuat di peta!", {
        autoClose: 2000,
      });
    }
  }, [isMapLoaded]);

  return (
    <MapContainer
      center={mapCenter}
      zoom={5}
      style={{ height: "500px", width: "100%" }}
    >
      {/* Peta OpenStreetMap */}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a>'
      />

      {/* Looping Data Gempa */}
      {earthquakes.length > 0
        ? earthquakes.map((quake, index) => (
            <CircleMarker
              key={index}
              center={[quake.latitude, quake.longitude]}
              radius={Math.max(quake.magnitude * 2, 3)} // Ukuran berdasarkan magnitudo
              color="red"
              fillColor="orange"
              fillOpacity={0.7}
            >
              <Popup>
                <strong>Magnitudo:</strong> {quake.magnitude} <br />
                <strong>Lokasi:</strong> {quake.location} <br />
                <strong>Waktu:</strong> {new Date(quake.date).toLocaleString()}
              </Popup>
            </CircleMarker>
          ))
        : !loading &&
          showToast("error", "Tidak ada data gempa yang bisa dimuat", {
            autoClose: 3000,
          })}
    </MapContainer>
  );
}
