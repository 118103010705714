import DataObjectIcon from "@mui/icons-material/DataObject";
import EmergencyShareIcon from "@mui/icons-material/EmergencyShare";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import KeyIcon from "@mui/icons-material/Key";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MapIcon from "@mui/icons-material/Map";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PublicIcon from "@mui/icons-material/Public";
import SettingsIcon from "@mui/icons-material/Settings";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { API_URL } from "../../config/apiConfig";
import { validateToken } from "../../config/authUtils";

const MySwal = withReactContent(Swal);

const mainListItems = [
  {
    text: "Home",
    icon: <HomeRoundedIcon />,
    route: "/admin",
    roles: ["admin", "user"],
  },
  {
    text: "Your Api Key",
    icon: <KeyIcon />,
    route: "/admin/your-api-key",
    roles: ["admin", "user"],
  },
  {
    text: "Clients",
    icon: <PeopleRoundedIcon />,
    route: "/admin/clients",
    roles: ["admin"],
  },
  {
    text: "Earthquake Data",
    icon: <PublicIcon />,
    route: "/admin/earthquake",
    roles: ["admin", "user"],
  },
  {
    text: "Location",
    icon: <MapIcon />,
    route: "/admin/location",
    roles: ["admin"],
  },
  {
    text: "Evacuation Point",
    icon: <EmergencyShareIcon />,
    route: "/admin/evacuation-point",
    roles: ["admin"],
  },
  {
    text: "Evacuation Simulation",
    icon: <PlayCircleIcon />,
    route: "/admin/evacuation-simulation",
    roles: ["admin", "user"],
  },
  {
    text: "AI and Data",
    icon: <DataObjectIcon />,
    route: "/admin/ai-and-data",
    roles: ["admin"],
  },
  {
    text: "Settings",
    icon: <SettingsIcon />,
    route: "/admin/settings",
    roles: ["admin"],
  },
];

const secondaryListItems = [
  {
    text: "Logout",
    icon: <LogoutRoundedIcon />,
    action: "logout",
    roles: ["admin", "user"],
  },
];

export default function MenuContent() {
  const [role, setRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRole = async () => {
      const result = await validateToken();
      if (result.isValid) {
        setRole(result.role);
      } else {
        navigate("/auth/sign-in"); // Redirect jika token tidak valid
      }
    };

    fetchRole();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      const result = await MySwal.fire({
        title: "Are you sure?",
        text: "You will be logged out of your account!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, log out!",
      });

      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          Swal.fire("Error!", "No valid session found.", "error");
          return;
        }

        const response = await fetch(`${API_URL}/v1/auth/logout`, {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
          localStorage.removeItem("token");
          Swal.fire("Success!", "Logout successful.", "success");
          navigate("/auth/sign-in");
        } else {
          const errorData = await response.json();
          Swal.fire(
            "Error!",
            errorData.message || "Failed to log out.",
            "error"
          );
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
      console.error("Logout error:", error);
    }
  };

  // Fungsi untuk menambahkan prefiks berdasarkan role
  const getPrefixedRoute = (route) => {
    if (role === "admin") return route;
    if (role === "user") return route.replace("/admin", "/user");
    return route;
  };

  // Filter menu berdasarkan role
  const filteredMainListItems = mainListItems.filter((item) =>
    item.roles.includes(role)
  );
  const filteredSecondaryListItems = secondaryListItems.filter((item) =>
    item.roles.includes(role)
  );

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {filteredMainListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate(getPrefixedRoute(item.route))} // Tambahkan prefiks di sini
          >
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {filteredSecondaryListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            onClick={item.action === "logout" ? handleLogout : undefined}
          >
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
