import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { API_URL } from "../../config/apiConfig";
import Copyright from "../internals/components/Copyright";

export default function AppSettingsGrid() {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [lastUpdated, setLastUpdated] = useState({}); // Simpan timestamp perubahan
  const token = localStorage.getItem("token");

  // Ambil data setting dari API
  const fetchSettings = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/v1/app-settings/`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) throw new Error("Failed to fetch settings");

      const result = await response.json();

      if (result.data && Array.isArray(result.data)) {
        setSettings(
          result.data.map((item) => ({
            key: item.key,
            value: item.value,
            updatedAt: item.updated_at, // Ambil timestamp dari API
          }))
        );

        // Simpan timestamp ke state lastUpdated
        const timestamps = {};
        result.data.forEach(({ key, updated_at }) => {
          timestamps[key] = updated_at;
        });
        setLastUpdated(timestamps);
      } else {
        throw new Error("Invalid settings data format");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to fetch settings", "error");
    }
    setLoading(false);
  }, [token]); // Menambahkan token sebagai dependensi

  const handleUpdate = async (key, value) => {
    setUpdating(true);

    // Mapped endpoint untuk setiap setting
    const endpointMap = {
      site_name: "name",
      app_version: "version",
      maintenance_mode: "maintenance",
      max_upload_size: "upload-size",
    };

    const apiKey = endpointMap[key] || key;

    try {
      // Ambil waktu pembaruan terakhir dari API
      const response = await fetch(`${API_URL}/v1/app-settings/${apiKey}`, {
        method: "GET",
      });

      if (!response.ok) throw new Error("Failed to fetch last update time");

      const result = await response.json();

      // Ambil waktu pembaruan terakhir dari response
      const lastUpdatedTime = result.data.updated_at;
      const lastUpdatedDate = new Date(lastUpdatedTime).getTime();

      const currentTime = new Date().getTime();
      const oneDay = 24 * 60 * 60 * 1000;

      // Jika pembaruan terakhir kurang dari 24 jam
      // if (currentTime - lastUpdatedDate < oneDay) {
      //   Swal.fire(
      //     "Warning",
      //     `You can only update ${key} once every 24 hours.`,
      //     "warning"
      //   );
      //   setUpdating(false);
      //   return;
      // }

      // Jika sudah lebih dari 24 jam, lakukan pembaruan
      const updateResponse = await fetch(
        `${API_URL}/v1/app-settings/${apiKey}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ value }),
        }
      );

      if (!updateResponse.ok) throw new Error(`Failed to update ${key}`);

      Swal.fire("Success", `${key} updated successfully!`, "success");
      fetchSettings(); // Ambil kembali data setelah update
    } catch (error) {
      Swal.fire("Error", error.message || `Failed to update ${key}`, "error");
    }

    setUpdating(false);
  };

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ width: "100%", maxWidth: "800px", mx: "auto", mt: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Application Settings
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Setting</b>
              </TableCell>
              <TableCell>
                <b>Value</b>
              </TableCell>
              <TableCell>
                <b>Last Updated</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {settings.map(({ key, value }) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>
                  {key === "maintenance_mode" || key === "manual_mode" ? (
                    <Select
                      fullWidth
                      size="small"
                      value={value.toString()}
                      onChange={(e) =>
                        setSettings((prev) =>
                          prev.map((item) =>
                            item.key === key
                              ? { ...item, value: e.target.value }
                              : item
                          )
                        )
                      }
                    >
                      <MenuItem value="true">Enabled</MenuItem>
                      <MenuItem value="false">Disabled</MenuItem>
                    </Select>
                  ) : key === "default_language" ? (
                    <Select
                      fullWidth
                      size="small"
                      value={value}
                      onChange={(e) =>
                        setSettings((prev) =>
                          prev.map((item) =>
                            item.key === key
                              ? { ...item, value: e.target.value }
                              : item
                          )
                        )
                      }
                    >
                      <MenuItem value="id">Bahasa Indonesia</MenuItem>
                      <MenuItem value="en">English</MenuItem>
                    </Select>
                  ) : key === "max_upload_size" ? (
                    <Select
                      fullWidth
                      size="small"
                      value={value}
                      onChange={(e) =>
                        setSettings((prev) =>
                          prev.map((item) =>
                            item.key === key
                              ? { ...item, value: e.target.value }
                              : item
                          )
                        )
                      }
                    >
                      <MenuItem value="1MB">1MB</MenuItem>
                      <MenuItem value="10MB">10MB</MenuItem>
                      <MenuItem value="50MB">50MB</MenuItem>
                      <MenuItem value="100MB">100MB</MenuItem>
                      <MenuItem value="500MB">500MB</MenuItem>
                      <MenuItem value="1GB">1GB</MenuItem>
                      <MenuItem value="5GB">5GB</MenuItem>
                    </Select>
                  ) : (
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={value}
                      onChange={(e) =>
                        setSettings((prev) =>
                          prev.map((item) =>
                            item.key === key
                              ? { ...item, value: e.target.value }
                              : item
                          )
                        )
                      }
                    />
                  )}
                </TableCell>
                <TableCell>
                  {lastUpdated[key]
                    ? new Date(lastUpdated[key]).toLocaleString()
                    : "Never"}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpdate(key, value)}
                    disabled={updating}
                  >
                    {updating ? "Updating..." : "Update"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
