import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Copyright from "../internals/components/Copyright";
import LatestEarthquakeInformation from "./LatestEarthquakeInformation";
import EarthquakeBarChart from "./EarthquakeBarChart";
import EarthquakeDistributionMaps from "./EarthquakeDistributionMaps";

export default function MainGrid() {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: { sm: "100%", md: "1700px" },
        mx: "auto",
        p: 2,
      }}
    >
      {/* Header */}
      <Typography component="h2" variant="h6" sx={{ mb: 3 }}>
        Overview
      </Typography>

      {/* Grid Layout */}
      <Grid container spacing={3}>
        {/* Informasi Gempa Terbaru */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 2, boxShadow: 2, borderRadius: 2 }}>
            <LatestEarthquakeInformation />
          </Box>
        </Grid>

        {/* Grafik Statistik Gempa */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 2, boxShadow: 2, borderRadius: 2 }}>
            <EarthquakeBarChart />
          </Box>
        </Grid>

        {/* Peta Distribusi Gempa (Full Width) */}
        <Grid item xs={12}>
          <Box sx={{ p: 2, boxShadow: 2, borderRadius: 2 }}>
            <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
              Earthquake Distribution Map
            </Typography>
            <EarthquakeDistributionMaps />
          </Box>
        </Grid>
      </Grid>

      {/* Footer */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Copyright />
      </Box>
    </Box>
  );
}
