import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";

export function GmpeEarthquake({ eventId, token, apiUrl }) {
  const [gmpeData, setGmpeData] = useState([]);
  const [gmpeError, setGmpeError] = useState(null);

  const fetchShakeData = useCallback(async () => {
    if (!token || !eventId) return;

    try {
      const result = await fetch(`${apiUrl}/v1/earthquakes/gmpe/${eventId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!result.ok) {
        setGmpeData([]);
        if (result.status !== 404) {
          setGmpeError(`Error: ${result.status} - ${result.statusText}`);
        }
        return;
      }

      const data = await result.json();
      if (data.responseCode === 200 && Array.isArray(data.data)) {
        setGmpeData(data.data);
      } else {
        setGmpeData([]);
      }
    } catch (err) {
      setGmpeError(err.message);
      setGmpeData([]);
    }
  }, [token, eventId, apiUrl]);

  useEffect(() => {
    fetchShakeData();
  }, [fetchShakeData]);

  return (
    <Box>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Ground Motion Prediction Equation (GMPE)
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>GMPE Model</strong>
              </TableCell>
              <TableCell>
                <strong>Weight</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gmpeError ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography color="error">{gmpeError}</Typography>
                </TableCell>
              </TableRow>
            ) : gmpeData.length > 0 ? (
              gmpeData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{data.gmpes}</TableCell>
                  <TableCell>{data.weights}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No GMPE data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
