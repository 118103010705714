import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { MaterialReactTable } from "material-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL, SHAKEMAP_API_URL } from "../../config/apiConfig";
import { getUserRole } from "../../utils/getUserRole";
import Copyright from "../internals/components/Copyright";

export default function EarthquakeGrid() {
  const [earthquakes, setEarthquakes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [role, setRole] = useState(null); // Tambahkan state untuk menyimpan role
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const userRole = getUserRole();

  // Fetch role pengguna dan data gempa
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Validasi token untuk mendapatkan role
        const response = await fetch(`${API_URL}/v1/auth/validate-token`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRole(data.data.role); // Simpan role dari API
        } else {
          throw new Error("Failed to validate user role");
        }

        // Fetch earthquake data
        const earthquakesResponse = await fetch(`${API_URL}/v1/earthquakes`);
        if (!earthquakesResponse.ok) {
          throw new Error("Failed to fetch earthquakes");
        }

        const earthquakeData = await earthquakesResponse.json();
        const sortedData = Array.isArray(earthquakeData.data)
          ? earthquakeData.data.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            )
          : [];

        setEarthquakes(sortedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const revokeEarthquake = async (id) => {
    if (role !== "admin") {
      Swal.fire(
        "Access Denied",
        "You do not have permission to revoke data.",
        "error"
      );
      return;
    }

    // Konfirmasi sebelum menghapus
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, revoke it!",
    });

    if (!result.isConfirmed) {
      return; // Jika user membatalkan, hentikan proses
    }

    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/v1/earthquakes/delete/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to revoke earthquake");
      }

      setEarthquakes((prevEarthquakes) =>
        prevEarthquakes.filter((quake) => quake.id !== id)
      );

      Swal.fire("Success", "Earthquake revoked successfully.", "success");
    } catch (err) {
      setError(err.message);
      Swal.fire("Error", "Failed to revoke earthquake.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddEvent = async () => {
    if (role !== "admin") {
      Swal.fire(
        "Access Denied",
        "You do not have permission to add data.",
        "error"
      );
      return;
    }

    const { value: eventId } = await Swal.fire({
      title: "Add Earthquake Event",
      input: "text",
      inputLabel: "Enter Event ID",
      inputPlaceholder: "Example: earth1122",
      showCancelButton: true,
      confirmButtonText: "Process",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      preConfirm: async (eventId) => {
        if (!eventId) {
          Swal.showValidationMessage("Event ID is required");
          return;
        }

        Swal.fire({
          title: "Processing...",
          html: "Processing earthquake event, please wait...",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        try {
          const response = await fetch(
            `${SHAKEMAP_API_URL}/v1/process/${eventId}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          const result = await response.json();
          if (!response.ok) {
            throw new Error(
              result.message || "Processing failed, please try again."
            );
          }

          Swal.fire({
            title: "Success",
            text: "Earthquake event processed successfully.",
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          Swal.fire("Error", error.message, "error");
        }
      },
    });

    if (!eventId) return;
  };

  const viewEarthquakeDetails = (id) => {
    navigate(`/${userRole}/earthquake/${id}`);
  };

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  const columns = [
    {
      accessorKey: "eventId",
      header: "Event ID",
    },
    {
      accessorKey: "location",
      header: "Location",
    },
    {
      accessorKey: "magnitude",
      header: "Magnitude",
    },
    {
      accessorKey: "depth",
      header: "Depth",
    },
    {
      accessorKey: "date",
      header: "Date",
      Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString("en-US"),
    },
    {
      accessorKey: "time",
      header: "Time",
      Cell: ({ cell }) => cell.getValue(),
    },
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <Box display="flex" gap={1}>
          {/* Tombol "View Details" tetap tersedia untuk semua user */}
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => viewEarthquakeDetails(row.original.id)}
          >
            View Details
          </Button>

          {/* Tombol "Revoke" hanya muncul jika role adalah "admin" */}
          {role === "admin" && (
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => revokeEarthquake(row.original.id)}
            >
              Revoke
            </Button>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" }, p: 2 }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        EARTHQUAKE DATA
      </Typography>

      {role === "admin" && (
        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 2 }}
          onClick={handleAddEvent}
        >
          Add Earthquake Event
        </Button>
      )}

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">Error: {error}</Typography>
      ) : earthquakes.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">No earthquake data available.</Typography>
        </Box>
      ) : (
        <MaterialReactTable columns={columns} data={earthquakes} />
      )}

      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
