import { useState, useEffect } from "react";

const STORAGE_KEY = "evacuation_result";
const EXPIRATION_TIME = 5 * 60 * 1000; // 5 menit dalam milidetik

export function saveToSessionStorage(data) {
  sessionStorage.removeItem(STORAGE_KEY); // Hapus data lama sebelum menyimpan yang baru
  const payload = {
    data,
    timestamp: new Date().getTime(),
  };
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(payload));

  // Trigger event agar komponen tahu data berubah
  window.dispatchEvent(new Event("storageUpdate"));
}

export function loadFromSessionStorage() {
  const storedData = sessionStorage.getItem(STORAGE_KEY);
  if (!storedData) return null;

  const parsedData = JSON.parse(storedData);
  const currentTime = new Date().getTime();

  if (currentTime - parsedData.timestamp < EXPIRATION_TIME) {
    return parsedData.data;
  }

  sessionStorage.removeItem(STORAGE_KEY);
  return null;
}

export function useSessionStorage() {
  const [data, setData] = useState(loadFromSessionStorage());

  useEffect(() => {
    function handleStorageUpdate() {
      setData(loadFromSessionStorage());
    }

    window.addEventListener("storageUpdate", handleStorageUpdate);
    return () => {
      window.removeEventListener("storageUpdate", handleStorageUpdate);
    };
  }, []);

  return data;
}
