import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormLabel,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";

export default function PasswordField({
  password,
  setPassword,
  error,
  errorMessage,
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl>
      <FormLabel htmlFor="password">Password</FormLabel>
      <TextField
        error={error}
        helperText={errorMessage}
        name="password"
        type={showPassword ? "text" : "password"}
        id="password"
        required
        fullWidth
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}
