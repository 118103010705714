import { toast } from "react-toastify";

/**
 * Menampilkan toast notifikasi
 * @param {"success" | "info" | "warning" | "error"} type - Jenis toast
 * @param {string} message - Pesan notifikasi
 * @param {object} [options={}] - Opsi tambahan dari react-toastify
 */
export const showToast = (type, message, options = {}) => {
  toast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    ...options,
  });
};
