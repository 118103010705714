import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { MaterialReactTable } from "material-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL } from "../../config/apiConfig";
import Copyright from "../internals/components/Copyright";
import { ModalAddEvacuationPoint } from "./ModalAddEvacuationPoint";

export default function EvacuationPointGrid() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [evacuationPoints, setEvacuationPoints] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [formData, setFormData] = useState({
    province: "",
    city: "",
    district: "",
    village: "",
    location_code: "",
    latitude: "",
    longitude: "",
  });

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvacuationPoints = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/evacuation-points/all`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 404) {
          setEvacuationPoints([]);
          return;
        }

        if (!response.ok) {
          throw new Error("Failed to fetch evacuation points");
        }

        const result = await response.json();
        setEvacuationPoints(result.data || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchProvinces = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/admin/locations/all`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Periksa apakah respons berhasil
        if (!response.ok) throw new Error("Failed to fetch locations");

        const result = await response.json();

        // Validasi keberhasilan berdasarkan responseCode
        if (result.responseCode !== 200) {
          throw new Error(result.message || "Failed to fetch locations");
        }

        // Filter hanya data provinsi (code length 1, e.g., "11")
        const provincesData = result.data.filter(
          (data) => data.code.split(".").length === 1
        );
        setProvinces(provincesData);
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    };

    fetchEvacuationPoints();
    fetchProvinces();
  }, [token, navigate]);

  const fetchCities = async (provinceCode) => {
    try {
      const response = await fetch(`${API_URL}/v1/admin/locations/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Periksa apakah respons berhasil
      if (!response.ok) throw new Error("Failed to fetch locations");

      const result = await response.json();

      // Validasi keberhasilan berdasarkan responseCode
      if (result.responseCode !== 200) {
        throw new Error(result.message || "Failed to fetch locations");
      }

      // Filter only cities under the selected province
      const citiesData = result.data.filter(
        (item) =>
          item.code.startsWith(provinceCode) &&
          item.code.split(".").length === 2
      );
      setCities(citiesData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDistricts = async (cityCode) => {
    try {
      const response = await fetch(`${API_URL}/v1/admin/locations/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Periksa apakah respons berhasil
      if (!response.ok) throw new Error("Failed to fetch locations");

      const result = await response.json();

      // Validasi keberhasilan berdasarkan responseCode
      if (result.responseCode !== 200) {
        throw new Error(result.message || "Failed to fetch locations");
      }

      // Filter only districts under the selected city
      const districtsData = result.data.filter(
        (item) =>
          item.code.startsWith(cityCode) && item.code.split(".").length === 3
      );
      setDistricts(districtsData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVillages = async (districtCode) => {
    try {
      const response = await fetch(`${API_URL}/v1/admin/locations/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Periksa apakah respons berhasil
      if (!response.ok) throw new Error("Failed to fetch locations");

      const result = await response.json();

      // Validasi keberhasilan berdasarkan responseCode
      if (result.responseCode !== 200) {
        throw new Error(result.message || "Failed to fetch locations");
      }

      // Filter only villages under the selected district
      const villagesData = result.data.filter(
        (item) =>
          item.code.startsWith(districtCode) &&
          item.code.split(".").length === 4
      );
      setVillages(villagesData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAdd = async () => {
    try {
      const response = await fetch(`${API_URL}/v1/evacuation-points`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to add evacuation point");
      }

      const result = await response.json();
      setEvacuationPoints((prev) => [...prev, result.data]);
      setOpenDialog(false);
      setFormData({
        province: "",
        city: "",
        district: "",
        village: "",
        location_code: "",
        latitude: "",
        longitude: "",
      });

      // Menampilkan SweetAlert2
      Swal.fire({
        title: "Success!",
        text: "Evacuation point added successfully.",
        icon: "success",
        timer: 3000, // 3 detik
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      // Tunggu 3 detik sebelum menyegarkan halaman
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      // Tampilkan konfirmasi sebelum delete
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // Lanjutkan penghapusan jika dikonfirmasi
        const response = await fetch(`${API_URL}/v1/evacuation-points/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to delete evacuation point");
        }

        // Hapus data dari state
        setEvacuationPoints((prev) => prev.filter((point) => point.id !== id));

        // Tampilkan SweetAlert sukses
        Swal.fire("Deleted!", "Evacuation point has been deleted.", "success");
      }
    } catch (error) {
      setError(error.message);

      // Tampilkan SweetAlert error
      Swal.fire("Error!", error.message, "error");
    }
  };

  const handleViewDetail = async (id) => {
    navigate(`/admin/evacuation-point/${id}`);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const columns = [
    {
      accessorKey: "location.province.name",
      header: "Province",
    },
    {
      accessorKey: "location.city.name",
      header: "City",
    },
    {
      accessorKey: "location.district.name",
      header: "District",
    },
    {
      accessorKey: "location.village.name",
      header: "Village",
    },
    {
      accessorKey: "latitude",
      header: "Latitude",
    },
    {
      accessorKey: "longitude",
      header: "Longitude",
    },
    {
      accessorKey: "created_at",
      header: "Created At",
      Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
    },
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => handleViewDetail(row.original.id)}
          >
            View Detail
          </Button>
          <Button
            cvariant="contained"
            size="small"
            color="error"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" }, p: 2 }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        EVACUATION POINT DATA
      </Typography>

      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 2 }}
        onClick={() => setOpenDialog(true)}
      >
        Add Evacuation Point
      </Button>

      <MaterialReactTable
        columns={columns}
        data={evacuationPoints}
        enableSorting
        enablePagination
        enableBottomToolbar
      />

      {/* Jika data kosong, tampilkan pesan informasi */}
      {evacuationPoints.length === 0 && (
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Alert severity="info">No evacuation points available.</Alert>
        </Box>
      )}

      <ModalAddEvacuationPoint
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        formData={formData}
        setFormData={setFormData}
        provinces={provinces}
        cities={cities}
        districts={districts}
        villages={villages}
        fetchCities={fetchCities}
        fetchDistricts={fetchDistricts}
        fetchVillages={fetchVillages}
        handleAdd={handleAdd}
      />

      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
