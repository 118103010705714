import React from "react";

function Maintenance() {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="text-3xl font-bold text-yellow-500">Maintenance Mode</h1>
      <p className="text-gray-600">
        Sistem sedang dalam pemeliharaan. Silakan coba lagi nanti.
      </p>
    </div>
  );
}

export default Maintenance;
