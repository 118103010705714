import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { API_URL } from "./config/apiConfig";
import ProtectedRoute from "./config/ProtectedRoute";
import RestrictedRoute from "./config/RestrictedRoute";
import AdminLayout from "./layouts/AdminLayout";
import AdminSignUp from "./Authentication/AdminRegistration/AdminRegistration";
import SignIn from "./Authentication/SignIn/SignIn";
import SignUp from "./Authentication/SingUp/SignUp";
import Dashboard from "./Dashboard/Dashboard";
import YourApiKey from "./Dashboard/YourApiKey";
import UserClient from "./Dashboard/UserClient";
import Earthquake from "./Dashboard/Earthquake";
import DetailEarthquake from "./Dashboard/DetailEarthquake";
import Location from "./Dashboard/Location";
import EvacuationPoint from "./Dashboard/EvacuationPoint";
import DetailEvacuationPoint from "./Dashboard/DetailEvacuationPoint";
import EvacuationSimulation from "./Dashboard/EvacuationSimulation";
import AiModel from "./Dashboard/AiModel";
import AppSettings from "./Dashboard/AppSettings";
import Maintenance from "./ErrorPages/Maintenance";
import ServerError from "./ErrorPages/ServerError";
import Forbidden from "./ErrorPages/Forbidden";
import NotFound from "./ErrorPages/NotFound";
import { useSSE } from "./utils/useSSE";

function App() {
  const [isAdminRegistered, setIsAdminRegistered] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  useSSE();

  const checkAdminAccount = async () => {
    try {
      const adminResponse = await fetch(`${API_URL}/v1/auth/admin-check`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (adminResponse.status === 503) {
        setIsMaintenance(true);
        return;
      }

      if (adminResponse.status === 404) {
        setIsAdminRegistered(false);
        return;
      }

      if (!adminResponse.ok) {
        setServerError(true);
        return;
      }

      const data = await adminResponse.json();
      setIsAdminRegistered(data.responseCode !== 404);
      setServerError(false);
    } catch (error) {
      setServerError(true);
      console.error("Error checking admin account:", error);
    }
  };

  useEffect(() => {
    checkAdminAccount();
  }, []);

  if (isMaintenance) return <Maintenance />;
  if (serverError) return <ServerError retryCheck={checkAdminAccount} />;
  if (isAdminRegistered === null)
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-blue-500"></div>
      </div>
    );

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
      <Route path="/maintenance" element={<Maintenance />} />
      <Route
        path="/500"
        element={<ServerError retryCheck={checkAdminAccount} />}
      />
      <Route path="/403" element={<Forbidden />} />
      <Route path="*" element={<NotFound />} />

      {/* Autentikasi */}
      <Route element={<RestrictedRoute />}>
        <Route path="/auth">
          <Route index element={<Navigate to="sign-in" replace />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="admin-regis" element={<AdminSignUp />} />
        </Route>
      </Route>

      {/* Admin dengan AdminLayout */}
      <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="your-api-key" element={<YourApiKey />} />
          <Route path="clients" element={<UserClient />} />
          <Route path="earthquake" element={<Earthquake />} />
          <Route path="earthquake/:id" element={<DetailEarthquake />} />
          <Route path="location" element={<Location />} />
          <Route path="evacuation-point" element={<EvacuationPoint />} />
          <Route
            path="evacuation-point/:id"
            element={<DetailEvacuationPoint />}
          />
          <Route
            path="evacuation-simulation"
            element={<EvacuationSimulation />}
          />
          <Route path="ai-and-data" element={<AiModel />} />
          <Route path="settings" element={<AppSettings />} />
        </Route>
      </Route>

      {/* User */}
      <Route element={<ProtectedRoute allowedRoles={["user"]} />}>
        <Route path="/user">
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="your-api-key" element={<YourApiKey />} />
          <Route path="earthquake" element={<Earthquake />} />
          <Route path="earthquake/:id" element={<DetailEarthquake />} />
          <Route
            path="evacuation-simulation"
            element={<EvacuationSimulation />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
